@font-face {
  font-family: "MuseoSansCyrl-700";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("MuseoSansCyrl-700"), url("../fonts/museosanscyrl-700.woff2") format("woff2"), url("../fonts/museosanscyrl-700.woff") format("woff");
}

@font-face {
  font-family: "MuseoSansCyrl-300";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("MuseoSansCyrl-300"), url("../fonts/museosanscyrl-300.woff2") format("woff2"), url("../fonts/museosanscyrl-300.woff") format("woff");
}

.nav-menu__wrapper {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
}

.nav-menu {
  max-width: 766px;
  width: 100%;
}

.header-top__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  gap: 20px;
}

.nav-menu__wrapper a {
  font-size: 16px;
  line-height: 19px;
  color: #646464;
}

.firstS__wrapper h1 {
  font-size: 40px;
  line-height: 48px;
  color: #fff;
}

.header-top--contacts {
  display: flex;
  gap: 30px;
  max-width: 374px;
  width: 100%;
  justify-content: flex-end;
}

.header-top__address {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.header-top__address a {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #17aec4;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  gap: 20px;
}

.header-top__time p {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #646464;
}

.header-top__time p span {
  font-family: MuseoSansCyrl-700, arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #646464;
}

.header-nav {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
  justify-content: flex-end;
}

.header-nav a {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}

.header-bottom {
  padding-top: 40px;
}

.main-header__burger {
  width: 30px;
  height: 21px;
  display: block;
  border: none;
  background: 0 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease-in-out;
  transform: rotate(0);
  display: none;
  z-index: 2;
}

.main-header__burger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #535a60;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0);
  transition: 0.25s ease-in-out;
}

.main-header__burger span:nth-child(2) {
  top: 0;
}

.main-header__burger span:nth-child(3) {
  top: 9px;
}

.main-header__burger span:nth-child(4) {
  bottom: 0;
}

.main-header__burger--active span:nth-child(2) {
  top: 10px;
  transform: rotate(135deg);
}

.main-header__burger--active span:nth-child(3) {
  opacity: 0;
  left: 60px;
}

.main-header__burger--active span:nth-child(4) {
  bottom: 8px;
  transform: rotate(-135deg);
}

.overflowHid {
  overflow: hidden;
}

.main-header__nav--active .main-header__nav-box {
  right: 0;
  /* transition: all .7s ease;
   */
}

.main-header__nav-box {
  width: 100%;
  right: -765px;
}

.main-header__nav--active {
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.main-header__nav {
  position: fixed;
  right: 0;
  top: 0px;
  display: grid;
  justify-content: flex-end;
  transition: all 0.5s linear;
  z-index: 1;
}

.main-header__nav--active .main-header__nav-box {
  right: 0;
  transition: all 0.7s ease;
}

.main-header__nav-box {
  width: 480px;
  background: #fff;
  box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: -500px;
  height: 100%;
  transition: all 0.7s ease;
}

.main-header__nav ul {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  margin-left: 60px;
  border-bottom: 3px solid #f2f2f2;
}

.main-header__nav ul li:last-child {
  margin-bottom: 30px;
}

.mob-list__first {
  margin-bottom: 10px;
  margin-top: 20px;
}

.mob-list__first a {
  font-family: MuseoSansCyrl-700, arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.mob-list a {
  font-size: 16px;
  line-height: 19px;
  color: #646464;
}

.main-header__nav-box .header-top__address {
  margin-left: 60px;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 3px solid #f2f2f2;
}

@media (max-width: 1200px) and (min-width: 1024px) {
  .header-nav {
    gap: 15px;
  }
  .header-nav a {
    font-size: 15px;
  }
}

@media (max-width: 1023px) {
  .header-nav {
    display: none;
  }
  .main-header__burger {
    display: flex;
  }
  .nav-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .logo {
    max-width: 125px;
  }
  .main-header__nav ul {
    margin-bottom: 15px;
  }
  .header-top--contacts .header-top__address {
    display: none;
  }
  .main-header__nav ul {
    margin-left: 20px;
  }
  .main-header__nav ul {
    gap: 20px;
  }
  .main-header__nav ul li:last-child {
    margin-bottom: 15px;
  }
  .main-header__nav-box .header-top__address {
    margin-left: 20px;
  }
  .header-top--contacts {
    justify-content: flex-start;
  }
  .main-header__nav--active .main-header__nav-box {
    width: 100%;
    right: -765 px;
    overflow: scroll;
  }
}

.headerr {
  position: fixed;
  top: 0;
  z-index: 222;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .container {
    padding: 0 14px;
    max-width: 768px;
  }
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}

.footer__socia ul {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.footer__socia li {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.footer__socia img {
  width: auto;
}

.footer__list ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer__list a {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.footer__time {
  margin-top: 21px;
  margin-bottom: 10px;
}

.footer__time p {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.footer__time p span {
  font-family: "MuseoSansCyrl-700", arial, sans-serif;
  font-weight: 700;
}

.footer__address a {
  font-family: "MuseoSansCyrl-700", arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 19px;
}

@media (max-width: 1023px) {
  .footer__list {
    display: none;
  }
}

@media (max-width: 550px) {
  .footer__wrapper {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .footer__logo img {
    max-width: 255px;
  }
  .footer__socia ul {
    align-items: center;
  }
}
