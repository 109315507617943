@font-face {
  font-family: "MuseoSansCyrl-700";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("MuseoSansCyrl-700"),
    url("../fonts/museosanscyrl-700.woff2") format("woff2"),
    url("../fonts/museosanscyrl-700.woff") format("woff");
}


@font-face {
  font-family: "MuseoSansCyrl-300";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local("MuseoSansCyrl-300"),
    url("../fonts/museosanscyrl-300.woff2") format("woff2"),
    url("../fonts/museosanscyrl-300.woff") format("woff");
}
