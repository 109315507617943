.footer__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}

//footer {
//  background: linear-gradient(0deg, #2EAD9B, #2EAD9B);
//}

.footer__socia {
  ul {
    display: flex;
    flex-direction: column;
    gap: 11px;

  }

  li {
    display: flex;
    gap: 16px;
    align-items: center;

    width: 100%;
  }

  img {
    width: auto;
  }
}

.footer__list {
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  a {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
}

.footer__time {
  margin-top: 21px;
  margin-bottom: 10px;

  p {
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;

    span {
      font-family: $MonBold;
      font-weight: $Bold;
    }
  }
}

.footer__address {
  a {
    font-family: $MonBold;
    font-weight: $Bold;
    font-size: 16px;
    color: #FFFFFF;

    line-height: 19px;
  }
}


@media (max-width: 1023px) {
  .footer__list {
    display: none;
  }
}

@media (max-width: 550px) {
  .footer__wrapper {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .footer__logo {
    img {
      max-width: 255px;
    }
  }
  //.footer__time {
  //  p {
  //    text-align: center;
  //  }
  //}
  //.footer__address {
  //  a {
  //    text-align: center;
  //  }
  //}
  .footer__socia ul {
    align-items: center;
  }
}
