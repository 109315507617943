// screen-resolution
$container-max-width: 1200px;
$desktop-width-middle: 1440px;
$desktop-width: 1200px;
$tablet-width: 1024px;
$mobile-width: 767px;
$mobile-width-middle: 480px;
$mobile-width-min: 375px;

//padding
$desktop-padding: 0 30px;
$tablet-padding: 0 15px;

// retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

//fonts
$Mon: "MuseoSansCyrl-300", arial, sans-serif;
$MonBold: "MuseoSansCyrl-700", arial, sans-serif;

//font-weight
$Normal: 300;
$Bold: 700;

//colors
$Black: #000000;
$White: #ffffff;
$Gray: #343538;
$White-gray: #f2f2f2;
$Dark-red: #ce0000;
$Orange: #e25f27;
$Gray-rgba: rgba(52, 53, 56, 0.7);
$Orange-rgba: rgba(226, 95, 39, 0.7);


//margin

$marginHeader:40px;
